<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card-actions
          ref="chartCard"
          no-body
          action-refresh
          :title="$t('Reports.Overview.title')"
          @refresh="refreshCard('chartCard')"
        >
          <b-card-body>
            <b-row class="match-height">
              <!-- users chart -->
              <b-col>
                <b-card
                  :title="$t('Reports.Overview.charts.users.title')"
                  border-variant="secondary"
                >
                  <!-- users' apex chart -->
                  <vue-apex-charts
                    type="donut"
                    height="300"
                    class="my-1"
                    :options="usersChartData.chartOptions"
                    :series="usersChartData.chartSeries"
                  />
                  <!-- users' chart info -->
                  <b-row class="justify-content-md-center">
                    <b-col
                      v-for="(data,index) in usersChartData.chartInfo"
                      :key="data.name"
                      xl="4"
                      md="4"
                      sm="6"
                    >
                      <statistic-card-vertical
                        :icon="data.icon"
                        :statistic="getStatsForCard(index, usersChartData.chartSeries, 0)"
                        :statistic-title="data.name"
                        :color="data.iconColor"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <!-- types chart -->
              <b-col>
                <b-card
                  :title="$t('Reports.Overview.charts.types.title')"
                  border-variant="secondary"
                >
                  <!-- types' apex chart -->
                  <vue-apex-charts
                    type="donut"
                    height="300"
                    class="my-1"
                    :options="typeChartData.chartOptions"
                    :series="typeChartData.chartSeries"
                  />
                  <!-- types' chart info -->
                  <b-row class="justify-content-md-center">
                    <b-col
                      v-for="(data,index) in typeChartData.chartInfo"
                      :key="data.name"
                      xl="3"
                      md="4"
                      sm="6"
                    >
                      <statistic-card-vertical
                        :icon="data.icon"
                        :statistic="getStatsForCard(index, typeChartData.chartSeries, typeChartData.rhh_count)"
                        :statistic-title="data.name"
                        :color="data.iconColor"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <!-- Alert, to warn in case of error -->
            <b-alert
              v-model="showAlert"
              v-height-fade.appear
              variant="danger"
              dismissible
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertOctagonIcon"
                />
                <span><strong>{{ $t("Reports.Overview.alert.title") }}:</strong> {{ $t("Reports.Overview.alert.message") }}</span>
              </div>
            </b-alert>
          </b-card-body>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BCardBody, BAlert,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueApexCharts from 'vue-apexcharts'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { startOfMonth, endOfToday, formatRFC3339 } from 'date-fns'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAlert,
    VueApexCharts,
    StatisticCardVertical,
    BCardActions,
  },
  directives: {
    heightFade,
    Ripple,
  },
  data() {
    const commonChartOptions = {
      // Options for both charts
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                // showAlways: false,
                label: this.$i18n.t('Reports.Overview.charts.total'),
                color: undefined,
                formatter(w) {
                  return w.config.series.reduce((a, b) => a + b, 0)
                },
              },
            },
          },
        },
      },
      legend: { show: false },
      noData: { text: this.$i18n.t('Reports.Overview.charts.loading') },
      stroke: { width: 0 },
    }
    return {
      showAlert: false,
      typeChartData: {
        rhh_count: 0,
        chartSeries: [], // [210, 28, 126, 4],
        chartOptions: {
          ...commonChartOptions,
          labels: [
            this.$i18n.t('Reports.Overview.charts.types.eeud'),
            this.$i18n.t('Reports.Overview.charts.types.naturalPerson'),
            this.$i18n.t('Reports.Overview.charts.types.traditional'),
            // this.$i18n.t('Reports.Overview.charts.types.rhh'),
          ],
          colors: [
            $themeColors.success,
            $themeColors.warning,
            $themeColors.info,
            // $themeColors.danger,
          ],
        },
        chartInfo: [
          {
            icon: 'CpuIcon',
            name: this.$i18n.t('Reports.Overview.charts.types.eeud'),
            iconColor: 'success',
          },
          {
            icon: 'UsersIcon',
            name: this.$i18n.t('Reports.Overview.charts.types.naturalPerson'),
            iconColor: 'warning',
          },
          {
            icon: 'PenToolIcon',
            name: this.$i18n.t('Reports.Overview.charts.types.traditional'),
            iconColor: 'info',
          },
          {
            icon: 'AlertTriangleIcon',
            name: this.$i18n.t('Reports.Overview.charts.types.rhh'),
            iconColor: 'danger',
          },
        ],
      },
      usersChartData: {
        chartSeries: [], // [66, 18],
        chartOptions: {
          ...commonChartOptions,
          labels: [
            this.$i18n.t('Reports.Overview.charts.users.active'),
            this.$i18n.t('Reports.Overview.charts.users.inactive'),
          ],
          colors: [
            $themeColors.success,
            $themeColors.danger,
          ],
        },
        chartInfo: [
          {
            icon: 'CheckCircleIcon',
            name: this.$i18n.t('Reports.Overview.charts.users.active'),
            iconColor: 'success',
          },
          {
            icon: 'XCircleIcon',
            name: this.$i18n.t('Reports.Overview.charts.users.inactive'),
            iconColor: 'danger',
          },
        ],
      },
    }
  },
  created() {
    this.getOverview()
  },
  methods: {
    getOverview() {
      this.showAlert = false
      return this.$http.get('/supervision/overview', {
        params: {
          fromDate: formatRFC3339(startOfMonth(new Date())),
          toDate: formatRFC3339(endOfToday()),
        },
      })
        .then(response => {
          // console.log(response.data)
          const usersData = [response.data.users.active, response.data.users.transition]
          const typesData = [response.data.job_count.eeud, response.data.job_count.nat, response.data.job_count.trad]
          // DONE: Fill charts' data with response
          this.usersChartData.chartSeries = usersData
          this.typeChartData.chartSeries = typesData
          this.typeChartData.rhh_count = response.data.job_count.rhh
        })
        .catch(() => {
          // console.log('There was an error: ', error)
          this.showAlert = true
        })
    },
    getStatsForCard(index, chartSeries, def) {
      const len = chartSeries.length
      if (len > 0 && len > index) {
        return chartSeries[index]
      }
      return def
    },
    refreshCard(cardName) {
      this.getOverview().finally(() => {
        this.$refs[cardName].showLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
